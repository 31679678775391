/* eslint-disable camelcase */
import Vue from 'vue'

import { instance } from '@/api'
import { getAll } from '@/api/request'
import OrderController from '@/controllers/production/Order/OrderController'
import PositionController from '@/controllers/production/Position/PositionController'
import StageLineController from '@/controllers/production/StageLine/StageLineController'

import { formulaTotalWeight } from '@/utils/production/position/formulas'
import { fullName } from '@/utils/string'
import { defaultStageList, StageItemModel } from '@/classes/StageItemModel'
import { fixedNumberCopy } from '@/utils/number'
import { setAlertError, setAlertSuccess } from '@/utils/store/alert'
import { goToPage } from '@/utils/router'
import { getUserItem } from '@/utils/model/User'
import { syncItemById } from '@/utils/array/reactive'
import PositionApi from '@/api/production/Position/PositionApi'
import { positionModel } from '@/entities/position'
import { GOST_URL } from '@/api/handbook/warehouse/GOSTApi'

const state = () => ({
  order: null,
  orderLoading: false,

  currentPosition: {},
  currentPositionFormDataFilesDeleted: [],
  positions: [],
  activePositionIndex: 0,
  currentPositionId: null,
  isEditPosition: false,

  positionStatusLine: {},
  stageStore: {},
  statuses: [],
  tempStatuses: [],
  isCreateStage: false,
  isEditStage: false,
  currentStageListForConsignment: [],

  consignments: [],

  comments: [],

  gosts: []
})

const getters = {
  order: state => state.order,
  currentOrderId: state => state.order.id,
  currentOrderDeliveryPermission: state => state.order.delivery_permission,
  orderLoading: state => state.orderLoading,
  orderStatusId: state => state.order.status_id,

  positions: state => {
    return state.positions
  },
  tabsPositions: state => {
    if (!state.positions?.length) return []

    return state.positions.map(item => ({
      id: item.id,
      title: `№${item.sort} ${item.type.name}`,
      count: item.value
    }))
  },
  copyPositions: state => {
    if (!state.positions?.length) return []

    return state.positions.map(item => ({
      id: item.id,
      name: `№${item.sort} ${item.type.name} (${item.value})`
    }))
  },
  currentPosition: state => state.currentPosition,
  currentPositionActive: state => state.positions[state.activePositionIndex],
  activePositionIndex: state => state.activePositionIndex,
  currentPositionId: state => state.currentPositionId,
  isEditPosition: state => state.isEditPosition,

  statuses: state => {
    return state.statuses.map(status => ({
      ...status,
      user: getUserItem(status.user)
    }))
  },
  positionStatusLine: state => state.positionStatusLine,
  currentStageList: state => {
    return state.statuses.length
      ? state.statuses.map(item => item.status)
      : []
  },

  positionConsignments (state) {
    return state.consignments || []
  },
  positionConsignmentsLength: (_, getters) => getters.positionConsignments.length,

  totalCountConsignments (getters) {
    return getters.positionConsignments.reduce((previousValue, currentValue) => {
      return previousValue + +currentValue.value
    }, 0) || 0
  },

  totalDirtyWeightConsignments (state) {
    return positionModel.calcConsignmentsDirtyWeight(state.currentPosition)
  },

  stageStore (state) {
    return state.positionStatusLine.statuses?.map((stage) => {
      return {
        name: stage.status?.name,
        user: stage.user,
        listOfConsignment:
          state.currentPosition?.consignments?.filter(c => c.status?.id === stage.status?.id)
      }
    }) || []
  },

  isEditStage: state => state.isEditStage,
  isCreateStage: state => state.isCreateStage,

  comments: state => state.comments,

  getGostByMaterialId: state => id => {
    return state.gosts?.filter(item => item.materials?.some(material => material.id === id))
  }
}

const mutations = {
  SET_ONE_ORDER (state, order) {
    if (order.positions?.length) {
      order.positions = order.positions.map(position => {
        const name = fullName(position.responsible?.surname, position.responsible?.name)
        const responsible = position.responsible ? {
          id: position.responsible?.id,
          name
        } : null

        return { ...position, responsible }
      })
    }

    state.order = order
  },
  UPDATE_ORDER (state, order) {
    state.order = { ...order }
  },
  SET_ORDER_LOADING (state, loading) {
    state.orderLoading = loading
  },
  SET_ORDER_READY_FOR_SHIPMENT_MARKERS (state, { is_pickup, documents_are_ready }) {
    state.order.is_pickup = is_pickup
    state.order.documents_are_ready = documents_are_ready
  },
  SET_POSITIONS (state, positions) {
    state.positions = positions
  },

  ADD_POSITION (state, payload) {
    state.positions.push(payload)
  },
  REMOVE_POSITION (state, id) {
    state.positions = state.positions.filter(item => +item.id !== +id)
  },
  SET_POSITION_ACTIVE_INDEX (state, index) {
    state.activePositionIndex = index
  },
  SET_CURRENT_POSITION (state, index) {
    state.currentPositionFormDataFilesDeleted = []
    state.currentPosition = { ...state.positions[index] } || null
    state.positionStatusLine = { ...state.positions[index]?.statuses_line } || {}

    state.isCreateStage = !state.positions[index]?.statuses_line?.statuses
    state.isEditStage = false
  },
  SET_CURRENT_POSITION_CANCEL_EDIT (state) {
    state.currentPosition = { ...state.positions[state.activePositionIndex] } || null
    state.isEditPosition = false
  },
  SET_CURRENT_POSITION_ID (state, index = 0) {
    if (state.positions) {
      state.currentPositionId = state.positions[index]?.id
    }
  },
  SET_CURRENT_POSITION_VALUE (state, { value, id, isChangeWeight }) {
    state.currentPosition = {}
    state.currentPosition = { ...value }
    if (id) {
      const index = state.positions.findIndex(item => item.id === id)
      if (index !== -1) {
        Vue.set(
          state.positions,
          index,
          { ...state.positions[index], ...value }
        )

        if (isChangeWeight) {
          state.consignments = state.positions[index]?.consignments || []
        }
      }
    }
  },
  SET_CURRENT_POSITION_ACTIVE_VALUE (state, { data, index }) {
    Vue.set(
      state.positions,
      index,
      { ...state.positions[index], ...data }
    )
  },
  TOGGLE_IS_EDIT_POSITION (state) {
    state.isEditPosition = !state.isEditPosition
  },
  CHANGE_IS_EDIT_POSITION (state, payload) {
    state.isEditPosition = payload
  },
  SET_CURRENT_POSITION_STATUS_LINE (state, payload) {
    state.positions[state.activePositionIndex].statuses_line = { ...payload }
  },
  SET_CURRENT_POSITION_RESPONSIBLE (state, payload) {
    state.currentPosition.responsible = { ...payload }
    state.positions[state.activePositionIndex].responsible = { ...payload }
  },
  SET_CURRENT_POSITION_NAME (state, payload) {
    state.positions[state.activePositionIndex].name = payload
  },

  SET_CURRENT_POSITION_WORKSHOP (state, payload) {
    state.positions[state.activePositionIndex].workshop = payload
    state.positions[state.activePositionIndex].workshop_id = payload.id
    state.currentPosition.workshop = payload
    state.currentPosition.workshop_id = payload.id
  },

  SET_CURRENT_POSITION_FILES_DELETE (state, id) {
    if (!id) {
      state.currentPositionFormDataFilesDeleted = []
      return
    }
    state.currentPositionFormDataFilesDeleted.push(id)
  },

  // stage
  TOGGLE_IS_EDIT_STAGE (state) { state.isEditStage = !state.isEditStage },
  CHANGE_IS_EDIT_STAGE (state, payload) { state.isEditStage = payload },
  CHANGE_IS_CREATE_STAGE (state, payload) { state.isCreateStage = payload },
  SET_STAGE_STORE (state, stages) {
    state.stageStore = {}
    state.stageStore = stages
  },
  ADD_STATUS_BY_STATUS_LINE (state, index) {
    state.statuses.splice(state.statuses.length - 3, 0, new StageItemModel(index))
  },
  SET_POSITION_STATUS_LINE (state, index) {
    if (state.positions && state.positions[index]?.statuses_line) {
      state.statuses = [...state.positions[index]?.statuses_line.statuses]
      state.tempStatuses = JSON.parse(JSON.stringify([...state.positions[index]?.statuses_line.statuses]))
    } else {
      state.statuses = [...defaultStageList]
    }
  },
  SET_POSITION_STATUS_LINE_VALUE (state, value) {
    if (value.index || value.index === 0) {
      const data = {
        sort: value.index + 1,
        status: value.data.status,
        user: getUserItem(value.data.user)
      }
      state.statuses[value.index] = { ...data }
    } else {
      state.statuses = value || [...defaultStageList]
    }
  },
  SET_POSITION_STATUS_LINE_VALUE_TEMP (state, value) {
    state.tempStatuses = value
  },

  // consignment
  SET_CONSIGNMENTS (state, index) {
    state.consignments = state.positions[index]?.consignments || []
  },
  ADD_CONSIGNMENT (state, payload) {
    state.consignments.push(payload)
  },
  UPDATE_CONSIGNMENT_AFTER_SPLIT (state, payload) {
    const index = state.consignments.findIndex((elem) => elem.id === payload.id)

    state.consignments[index].value = state.consignments[index].value - payload.value
    if (state.consignments[index].weight_dirty) {
      const weight_dirty = state.consignments[index].weight_dirty - payload.weight_dirty
      state.consignments[index].weight_dirty = fixedNumberCopy(weight_dirty, 3)
    }
  },
  UPDATE_CONSIGNMENT (state, consignment) {
    syncItemById(consignment, state.consignments)
  },
  REMOVE_CONSIGNMENT (state, id) {
    const index = state.consignments.findIndex((elem) => elem.id === id)
    Vue.delete(state.consignments, index)
  },

  CLEAR_ORDER (state) {
    state.positionStatusLine = {}
    state.statuses = []
    state.positions = []
    state.consignments = []
    state.currentPosition = []
    state.comments = []
    state.activePositionIndex = 0
    state.currentPositionId = null
    state.currentPositionFormDataFilesDeleted = []
  },

  CLEAR_POSITION_DETAIL (state) {
    state.currentPosition = []
    state.currentPositionId = null
    state.positionStatusLine = {}
    state.statuses = []
    state.consignments = []
    state.comments = []
  },

  // comments
  SET_COMMENTS (state, data) {
    state.comments = data
  },

  // gosts
  SET_GOSTS (state, data) {
    state.gosts = data
  }
}

const actions = {
  async getOrderOne ({ state, commit, dispatch }, { id, positionIndex = 0 }) {
    commit('SET_ORDER_LOADING', true)
    try {
      const res = await OrderController.getById(id)
      if (res?.status !== 200) return goToPage('Order')
      commit('SET_ONE_ORDER', res.data)

      if (res.data?.waybills && res.data.waybills[0]) {
        dispatch('setWaybill', res.data.waybills)
      }

      // TODO вынести в отдельную функцию, т.к. это не используется в редактировании заказа
      if (res.data?.positions) {
        commit('SET_POSITIONS', res.data.positions)
        commit('SET_POSITION_ACTIVE_INDEX', positionIndex)
        commit('SET_CURRENT_POSITION_ID', positionIndex)
        commit('SET_CURRENT_POSITION_VALUE', { value: res.data.positions[positionIndex] })
        commit('warehouse/SET_ARTICLE', state.currentPosition.article, { root: true })
        await dispatch('getGostsAll')
      }
    } finally {
      commit('SET_ORDER_LOADING', false)
      // TODO вынести в отдельную функцию, т.к. это не используется в редактировании заказа
      commit('SET_CURRENT_POSITION_FILES_DELETE', null)
      dispatch('scroll/scrollToNode', {}, { root: true })
    }
  },

  setWaybill ({ commit }, waybills) {
    commit('waybill/SET_WAYBILL', waybills[0], { root: true })
  },

  async changeMassStatus ({ state, commit, dispatch, getters }, { id, direction }) {
    // TODO: запретить массовый переход если флаг delivery_permission: false, после интеграции с AmoCRM
    if (!getters.currentOrderDeliveryPermission) {
      await OrderController.updateDeliveryPermission(id)
    }

    const response = await OrderController.updateMassStatus(id, direction)
    if (response?.status !== 200) return
    await dispatch('getOrderOne', { id })
  },

  async restoreOrder ({ dispatch }, { id, externalId }) {
    const response = await OrderController.restoreOrder(id, externalId)
    if (response?.status !== 200) return
    await setAlertSuccess('Заказ восстановлен')
    await dispatch('getOrderOne', { id })
  },

  async changeOrderStatus ({ dispatch }, { id, status }) {
    const response = await OrderController.updateOrderStatus(id, status)
    if (response?.status !== 200) return
    await dispatch('getOrderOne', { id })
  },

  // eslint-disable-next-line camelcase
  async updateOrderMarkers ({ commit, dispatch }, { id, is_pickup, documents_are_ready, callback }) {
    const response = await OrderController.updateMarkers(id, { is_pickup, documents_are_ready })
    if (response?.status !== 200) return

    commit('SET_ORDER_READY_FOR_SHIPMENT_MARKERS', { is_pickup, documents_are_ready })
    callback()

    await setAlertSuccess('Заказ обновлен')
  },

  // position
  async loadCurrentPositionActive ({ commit, getters }) {
    const response = await PositionController.getById(getters.currentPositionId)
    commit('SET_CURRENT_POSITION_ACTIVE_VALUE', {
      data: response.data,
      index: getters.activePositionIndex
    })
    // try {
    //   const response = await PositionController.getById(getters.currentPositionId)
    //   commit('SET_CURRENT_POSITION_ACTIVE_VALUE', {
    //     data: response.data,
    //     index: getters.activePositionIndex
    //   })
    // } catch (e) {
    //   await setAlertError(e, 'Не удалось загрузить позицию')
    // }
  },

  async createPosition ({ dispatch }, data) {
    const response = await PositionController.create(data)
    if (response.status !== 201) return

    await dispatch('responseProcessingCreate', response.data)
  },

  async createPositionByTemplate ({ dispatch }, data) {
    const response = await PositionApi.createByTemplate(data)
    await dispatch('responseProcessingCreate', response.data)
  },

  responseProcessingCreate ({ commit, state }, data) {
    commit('CLEAR_POSITION_DETAIL')
    commit('ADD_POSITION', data)
    commit('SET_CURRENT_POSITION_VALUE', { value: data })

    const index = state.positions.length ? state.positions.length - 1 : 0
    commit('SET_POSITION_ACTIVE_INDEX', index)
    commit('SET_CURRENT_POSITION_ID', index)
    commit('CHANGE_IS_EDIT_POSITION', true)
    commit('SET_POSITION_STATUS_LINE', index)
    commit('CHANGE_IS_CREATE_STAGE', true)
  },

  async updatePositionCurrent ({ commit, state, getters, rootGetters, dispatch }, data) {
    const updateData = {
      ...data,
      changer_id: rootGetters.currentUserId
    }

    await PositionController.deleteFiles(state.currentPositionFormDataFilesDeleted)

    try {
      const response = await PositionController.update(data.id, updateData)
      if (response.status !== 200) return

      const isChangeWeight = +state.positions[getters.activePositionIndex].weight !== +data.weight

      if (isChangeWeight && state.consignments?.length) {
        data = {
          ...data,
          consignments: state.consignments.map((item, i) => ({
            ...item,
            weight_clear: +response.data.consignments[i].weight_clear
          }))
        }
      }
      commit('SET_CURRENT_POSITION_VALUE', { value: data, id: data.id, isChangeWeight })
      commit('SET_CURRENT_POSITION_RESPONSIBLE', data.responsible)
      commit('SET_CURRENT_POSITION_NAME', data.name)
      commit('CHANGE_IS_EDIT_POSITION', false)
    } finally {
      const files = await PositionController.postFiles(data.id, data.files)
      if (files) {
        state.positions[getters.activePositionIndex].files = files.data
        state.currentPosition.files = files.data
      }
      commit('SET_CURRENT_POSITION_FILES_DELETE', null)
    }
  },

  async removePosition ({ commit, dispatch, state }) {
    const response = await PositionController.destroy(state.currentPositionId)
    if (response?.status !== 204) return

    commit('REMOVE_POSITION', state.currentPositionId)

    if (state.positions.length) {
      await dispatch('setActivePosition', 0)
    } else {
      commit('CLEAR_ORDER')
    }
  },

  async setActivePosition ({ commit, dispatch, getters }, index) {
    commit('SET_POSITION_ACTIVE_INDEX', index)
    commit('SET_CURRENT_POSITION_ID', index)
    commit('SET_CURRENT_POSITION', index)
    commit('SET_POSITION_STATUS_LINE', index)
    commit('SET_CONSIGNMENTS', index)

    if (getters.currentPositionId) {
      await dispatch('getCommentAll')
    }
  },

  async copyPosition ({ dispatch }, data) {
    const response = await PositionApi.copyPosition(data)
    if (response.status !== 201) return

    await dispatch('responseProcessingCreate', response.data)
  },

  // stage line
  async createStageLine ({ commit, state, dispatch }, statuses) {
    try {
      const postData = {
        position_id: state.currentPositionId,
        statuses
      }

      const response = await StageLineController.create(postData)
      dispatch('syncStageLine', response.data)
    } catch (error) {
      await setAlertError(error, 'Ошибка при создании линии этапов')
      commit('SET_STAGE_STORE', [])
      commit('SET_POSITION_STATUS_LINE_VALUE', [...defaultStageList])
    }
  },

  async updateStageLine ({ commit, state, dispatch }, statuses) {
    try {
      const stageLineId = state.positions[state.activePositionIndex].statuses_line.id
      const updateData = {
        position_id: state.currentPositionId,
        statuses
      }

      const response = await StageLineController.update(stageLineId, updateData)
      dispatch('syncStageLine', response.data)
    } catch (error) {
      await setAlertError(error, 'Ошибка при обновлении линии этапов')
    } finally {
      commit('CHANGE_IS_EDIT_STAGE', false)
    }
  },

  syncStageLine ({ commit }, data) {
    commit('SET_CURRENT_POSITION_STATUS_LINE', { id: data.id, statuses: data.statuses })
    commit('SET_POSITION_STATUS_LINE_VALUE', data.statuses)
    commit('SET_POSITION_STATUS_LINE_VALUE_TEMP', data.statuses)
    commit('CHANGE_IS_CREATE_STAGE', false)
  },

  // consignment
  async createConsignment ({ commit, getters, state, rootGetters, dispatch }, data) {
    const calcWeight = formulaTotalWeight(state.positions[state.activePositionIndex])
    const weightClear = fixedNumberCopy((calcWeight * data.value), 2)

    const postData = {
      changer_id: rootGetters.currentUserId,
      position_id: state.currentPositionId,
      status_id: data.stageId || state.statuses[0].status.id,
      weight_clear: weightClear,
      ...data,
      probes: data.probes || 0
    }

    try {
      const response = await instance.post('/consignment/consignments', postData)
      commit('ADD_CONSIGNMENT', response.data)
      commit('warehouse/COUNT_CALC_BY_ARTICLE', { article: data.article, value: -data.value }, { root: true })
    } catch (e) {
      await setAlertError(e, 'Не удалось создать партию')
    }
  },

  async updateConsignment ({ commit, rootGetters, dispatch }, consignment) {
    const postData = {
      changer_id: rootGetters.currentUserId,
      ...consignment
    }
    const articleData = {
      article: consignment.article,
      value: consignment.value_article
    }
    const params = {
      id: postData.id,
      updateData: postData
    }

    try {
      const res = await instance.put(`/consignment/consignments/${params.id}`, params.updateData)
      commit('UPDATE_CONSIGNMENT', res.data)
      commit('warehouse/COUNT_CALC_BY_ARTICLE', articleData, { root: true })
    } catch (e) {
      await setAlertError(e, 'Не удалось обновить партию')
    }
  },

  // comments
  async getCommentAll ({ commit, getters }, id) {
    try {
      const params = {
        position_id: id || getters.currentPositionId,
        sort: '-id'
      }

      const res = await getAll('consignment/comments', params)
      commit('SET_COMMENTS', res.data)
    } catch (e) {
      await setAlertError(e, 'Не удалось загрузить список комментариев')
    }
  },

  // gosts
  async getGostsAll ({ commit, getters }) {
    try {
      const res = await getAll(GOST_URL)
      commit('SET_GOSTS', res.data)
    } catch (e) {
      await setAlertError(e, 'Не удалось загрузить список Гостов')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import ConsignmentApi from '@/api/production/Consignment/ConsignmentApi'
import { createBaseController } from '@/controllers/baseController'
import { createBaseResponseErrors } from '@/utils/error/responseErrors'
import { setAlertError } from '@/utils/store/alert'
import { goToPage } from '@/utils/router'

const CONSIGNMENT_ERRORS = {
  ...createBaseResponseErrors('партию', 'партий'),
  executor: 'Не удалось обновить исполнителя',
  stageLine: 'Не удалось загрузить линию этапов партии',
  weight: 'Не удалось обновить общий вес партии',
  next: 'Не удалось перевести партию на следующий этап',
  split: 'Не удалось раздробить партию',
  back: 'Не удалось переместить партию на этап назад',
  util: 'не удалось утилизировать партию'
}

export default {
  ...createBaseController(ConsignmentApi, CONSIGNMENT_ERRORS),
  getById: async (id, params) => {
    try {
      return await ConsignmentApi.getById(id, params)
    } catch (e) {
      if (e.response.status === 403) {
        await setAlertError(e, 'Недостаточно прав на просмотр партии')
      } else {
        await setAlertError(e, CONSIGNMENT_ERRORS.getById)
      }
      await goToPage('ConsignmentList')
    }
  },
  updateExecutor: async (id, data) => {
    try {
      return await ConsignmentApi.updateExecutor(id, data)
    } catch (e) {
      await setAlertError(e, CONSIGNMENT_ERRORS.executor)
    }
  },
  getStageLine: async id => {
    try {
      return await ConsignmentApi.getStageLine(id)
    } catch (e) {
      await setAlertError(e, CONSIGNMENT_ERRORS.stageLine)
    }
  },
  updateWeightDirty: async (id, data) => {
    try {
      return await ConsignmentApi.updateWeightDirty(id, data)
    } catch (e) {
      await setAlertError(e, CONSIGNMENT_ERRORS.weight)
    }
  },
  utilization: async (id, data) => {
    try {
      return await ConsignmentApi.utilization(id, data)
    } catch (e) {
      await setAlertError(e, CONSIGNMENT_ERRORS.util)
    }
  },
  goStageNext: async (id, data) => {
    try {
      return await ConsignmentApi.goStageNext(id, data)
    } catch (e) {
      await setAlertError(e, CONSIGNMENT_ERRORS.next)
    }
  },
  goSplit: async (id, data) => {
    try {
      return await ConsignmentApi.goSplit(id, data)
    } catch (e) {
      await setAlertError(e, CONSIGNMENT_ERRORS.split)
    }
  },
  goStageBack: async (id, data) => {
    try {
      return await ConsignmentApi.goStageBack(id, data)
    } catch (e) {
      await setAlertError(e, CONSIGNMENT_ERRORS.back)
    }
  }
}

import { instance } from '@/api'
import { createBaseApi } from '@/api/baseApi'

const CONSIGNMENT_URL = 'consignment/consignments'
export default {
  ...createBaseApi(CONSIGNMENT_URL),
  updateExecutor: (id, data) => {
    return instance.post(`/${CONSIGNMENT_URL}/${id}/executor`, data)
  },
  getStageLine: id => {
    return instance.get(`/${CONSIGNMENT_URL}/${id}/line`)
  },
  updateWeightDirty: (id, data) => {
    return instance.patch(`/${CONSIGNMENT_URL}/${id}/weight-dirty`, data)
  },
  utilization: (id, data) => {
    return instance.post(`/${CONSIGNMENT_URL}/${id}/scrap`, data)
  },
  goStageNext: (id, data) => {
    return instance.post(`/${CONSIGNMENT_URL}/${id}/next`, data)
  },
  goSplit: (id, data) => {
    return instance.post(`/${CONSIGNMENT_URL}/${id}/split`, data)
  },
  goStageBack: (id, data) => {
    return instance.post(`/${CONSIGNMENT_URL}/${id}/previous`, data)
  }
}
